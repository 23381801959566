import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 250)
              id
              frontmatter {
                title
                path
                description
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: posts } = data.allMarkdownRemark
      return (
        <Layout>
          <div className="intro">
            Beatflyer is the easiest way to make your designs stand out with
            catchy animations and effects, regardless if you use music or not.
            <br />
            While Beatflyer is much easier to use than any other professional
            tool, it also provides some very powerful features which are worth
            learning.
          </div>
          <div className="blog-posts">
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }, index) => {
                return (
                  <div className="blog-post-preview" key={post.id}>
                    <h2>
                      <Link to={post.frontmatter.path}>
                        {index + 1 + '. ' + post.frontmatter.title}
                      </Link>
                    </h2>
                    {post.frontmatter.description && (
                      <p>{post.frontmatter.description}</p>
                    )}
                  </div>
                )
              })}
          </div>
        </Layout>
      )
    }}
  />
)

export default IndexPage
